import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Avatar,
  Grid,
  Paper,
} from "@mui/material";
import { db, storage } from "../../shared/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  arrayRemove,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../shared/useAuth";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import ContainerBox from "../../shared/ContainerBox";
import { Helmet } from "react-helmet";
import { colors } from "../lib/theme";
import { ref as storageRef, deleteObject } from "firebase/storage";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [profilePhotoURL, setProfilePhotoURL] = useState("");
  const fileInputRef = useRef(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeletingData, setIsDeletingData] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  console.log("stripeCustomerId", stripeCustomerId);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripePublicKey = document.getElementById("react-root").dataset.stripePublicKey;
      const stripeInstance = await loadStripe(stripePublicKey);
      setStripe(stripeInstance);
    };

    initializeStripe();
  }, []);

  useEffect(() => {
    if (user && stripeCustomerId) {
      fetchSubscriptionData();
    }
  }, [user, stripeCustomerId]);

  // Ensure stripeCustomerId is set after fetching user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        setIsLoading(true);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        console.log("User data:", userData);
        setFirstName(userData?.firstName || "");
        setLastName(userData?.lastName || "");
        setEmail(user.email);
        setProfilePhotoURL(userData?.profilePhotoURL || "");
        setStripeCustomerId(userData?.stripeCustomerId || ""); // Ensure this is set
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  const fetchSubscriptionData = async () => {
    setIsLoadingSubscription(true);
    setSubscriptionError(null);
    console.log("Fetching subscription data...");
    try {
      if (!user?.uid) {
        console.log("No user ID found");
        setSubscription(null);
        return;
      }

      console.log("User ID:", user.uid);
      const response = await fetch(`/api/user_subscription/${stripeCustomerId}`);
      console.log("API response status:", response.status);
      if (!response.ok) {
        throw new Error('Failed to fetch subscription data');
      }
      const data = await response.json();
      console.log("Subscription data received:", data);

      if (data.subscription) {
        setSubscription(data.subscription);
      } else {
        console.error("No subscription found in response data:", data);
        setSubscription(null);
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
      setSubscriptionError("Failed to load subscription information.");
    } finally {
      setIsLoadingSubscription(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(`/api/user_subscription/${subscription.id}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken, // Include CSRF token
        },
      });
      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      // Update user's subscriptionLevel to 0
      await updateDoc(doc(db, "users", user.uid), {
        subscriptionLevel: 0,
      });

      // Refresh subscription data after cancellation
      fetchSubscriptionData();
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setSubscriptionError("Failed to cancel subscription. Please try again.");
    } finally {
      setOpenCancelDialog(false); // Close dialog after action
    }
  };

  const handleSave = async () => {
    if (user) {
      setIsSaving(true);
      setShowSuccess(false);
      setError(null);
      try {
        await updateDoc(doc(db, "users", user.uid), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        });
        setShowSuccess(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
        setError("Failed to update profile. Please try again.");
      }
      setIsSaving(false);
    }
  };

  const handleEmailChange = async () => {
    if (user) {
      setIsSaving(true);
      setShowSuccess(false);
      setError(null);
      try {
        // Re-authenticate the user
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);

        // Send verification email to the new address
        await verifyBeforeUpdateEmail(user, newEmail);

        setShowPasswordField(false);
        setPassword("");
        setShowSuccess(true);
        setError(
          "A verification email has been sent to your new email address. Please check your inbox and follow the instructions to complete the email change."
        );
      } catch (error) {
        console.error("Error updating email:", error);
        console.error("Error code:", error.code);
        console.error("Error message:", error.message);

        switch (error.code) {
          case "auth/requires-recent-login":
            setError(
              "For security reasons, please log out and log back in before changing your email."
            );
            break;
          case "auth/invalid-email":
            setError(
              "The new email address is invalid. Please check and try again."
            );
            break;
          case "auth/email-already-in-use":
            setError("This email is already in use by another account.");
            break;
          case "auth/wrong-password":
            setError("Incorrect password. Please check and try again.");
            break;
          default:
            setError(`Failed to update email: ${error.message}`);
        }
      }
      setIsSaving(false);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && user) {
      setIsSaving(true);
      setShowSuccess(false);
      setError(null);
      try {
        // Check file size (limit to 5MB)
        if (file.size > 5 * 1024 * 1024) {
          throw new Error("File size exceeds 5MB limit.");
        }

        // Check file type
        const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!allowedTypes.includes(file.type)) {
          throw new Error(
            "Invalid file type. Please upload a JPEG, PNG, or GIF image."
          );
        }

        // Generate a unique filename
        const fileName = `${Date.now()}_${file.name}`;

        // Create a reference that conforms to the storage rules
        const storageRef = ref(
          storage,
          `profile_photos/${user.uid}/${fileName}`
        );

        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        await updateDoc(doc(db, "users", user.uid), {
          profilePhotoURL: downloadURL,
        });
        setProfilePhotoURL(downloadURL);
        setShowSuccess(true);
      } catch (error) {
        console.error("Error uploading profile photo:", error);
        if (error.code === "storage/unauthorized") {
          setError(
            "You don't have permission to upload files. Please check your account settings."
          );
        } else if (error.code === "storage/canceled") {
          setError("Upload was cancelled. Please try again.");
        } else if (error.code === "storage/unknown") {
          setError("An unknown error occurred. Please try again later.");
        } else {
          setError(
            error.message || "Failed to upload profile photo. Please try again."
          );
        }
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleDeleteUserData = async () => {
    if (user) {
      setIsDeletingData(true);
      setError(null);
      setDeleteSuccess(false);
      try {
        // Delete albums
        const albumsQuery = query(
          collection(db, "albums"),
          where("userId", "==", user.uid)
        );
        const albumsSnapshot = await getDocs(albumsQuery);
        for (const albumDoc of albumsSnapshot.docs) {
          await deleteDoc(albumDoc.ref);
        }

        for (const albumDoc of albumsSnapshot.docs) {
          const albumData = albumDoc.data();

          // Process tracks within each album
          if (albumData.tracks) {
            let tracksUpdated = false;
            const updatedTracks = albumData.tracks.map((track) => {
              if (track.audioSegments && Array.isArray(track.audioSegments)) {
                const originalLength = track.audioSegments.length;
                track.audioSegments = track.audioSegments.filter(
                  (segment) => segment.recordedBy !== user.uid
                );

                // Delete audio files from storage
                track.audioSegments.forEach(async (segment) => {
                  if (segment.audioUrl) {
                    const audioRef = storageRef(storage, segment.audioUrl);
                    try {
                      await deleteObject(audioRef);
                    } catch (error) {
                      console.error("Error deleting audio file:", error);
                    }
                  }
                });

                if (track.audioSegments.length !== originalLength) {
                  tracksUpdated = true;
                }
              }
              return track;
            });

            // Only update the album if tracks were actually modified
            if (tracksUpdated) {
              try {
                console.log("Updating album:", albumDoc.id);
                console.log("Updated tracks:", JSON.stringify(updatedTracks));
                await updateDoc(albumDoc.ref, { tracks: updatedTracks });
              } catch (error) {
                console.error("Error updating album:", albumDoc.id, error);
                console.error("Error details:", error.code, error.message);
                // You might want to add some error handling here
                setError((prev) =>
                  prev
                    ? `${prev}\nFailed to update album ${albumDoc.id}: ${error.message}`
                    : `Failed to update album ${albumDoc.id}: ${error.message}`
                );
              }
            }
          }
        }

        // Remove user from shared albums
        const sharedAlbumsQuery = query(
          collection(db, "albums"),
          where("sharedWith", "array-contains", user.uid)
        );
        const sharedAlbumsSnapshot = await getDocs(sharedAlbumsQuery);
        for (const albumDoc of sharedAlbumsSnapshot.docs) {
          await updateDoc(albumDoc.ref, {
            sharedWith: arrayRemove(user.uid),
          });
        }

        // Remove user from invited albums
        const invitedAlbumsQuery = query(
          collection(db, "albums"),
          where("invitedTo", "array-contains", user.email)
        );
        const invitedAlbumsSnapshot = await getDocs(invitedAlbumsQuery);
        for (const albumDoc of invitedAlbumsSnapshot.docs) {
          await updateDoc(albumDoc.ref, {
            invitedTo: arrayRemove(user.email),
          });
        }

        // Update user document
        await updateDoc(doc(db, "users", user.uid), {
          firstName: "",
          lastName: "",
          profilePhotoURL: "",
        });

        setDeleteSuccess(true);
      } catch (error) {
        console.error("Error deleting user data:", error);
        if (error.code === "permission-denied") {
          setError(
            "You don't have permission to delete this data. This might be due to security rules or network issues. Please try logging out and back in, or contact support if the problem persists."
          );
        } else {
          setError(`Failed to delete user data: ${error.message}`);
        }
      } finally {
        setIsDeletingData(false);
        setOpenDeleteDialog(false);
      }
    }
  };

  const handleSubscribe = async () => {
    try {
      const response = await fetch('/api/user_subscription/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({
          stripe_customer_id: stripeCustomerId,
          email: email,
          firstName: firstName,
          lastName: lastName,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create subscription');
      }

      const data = await response.json();
      await updateDoc(doc(db, "users", user.uid), {
        subscriptionLevel: 1,
      });
      setSubscription(data.subscription);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error creating subscription:", error);
      setSubscriptionError("Failed to create subscription. Please try again.");
    }
  };

  if (loading || isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  return (
    <ContainerBox>
      <Helmet>
        <title>Profile - Golden Record</title>
        <meta name="description" content="Edit your Golden Record profile" />
      </Helmet>
      <Typography variant="h3" sx={{ mb: 4 }}>
        Your Profile
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box
              onClick={handleImageClick}
              sx={{
                mb: 3,
                cursor: "pointer",
                "&:hover": { opacity: 0.8 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                src={profilePhotoURL}
                alt={`${firstName} ${lastName}`}
                sx={{ width: 120, height: 120 }}
              />
              <Typography
                variant="caption"
                sx={{ mt: 1, display: "block", textAlign: "center" }}
              >
                Click to change profile photo
              </Typography>
            </Box>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              accept="image/*"
              style={{ display: "none" }}
            />
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="normal"
            />
            {showSuccess && (
              <Alert severity="success" sx={{ mt: 2, mb: 2, width: "100%" }}>
                Profile updated successfully!
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2, mb: 2, width: "100%" }}>
                {error}
              </Alert>
            )}
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isSaving}
              sx={{ mt: 2, mb: 3, display: "block" }}
            >
              {isSaving ? "Saving..." : "Save Changes"}
            </Button>
            <TextField
              fullWidth
              label="Current Email"
              value={email}
              disabled
              margin="normal"
            />
            <TextField
              fullWidth
              label="New Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              margin="normal"
            />
            {showPasswordField && (
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
            )}
            <Button
              variant="emphasized"
              onClick={() => {
                if (showPasswordField) {
                  handleEmailChange();
                } else {
                  setShowPasswordField(true);
                }
              }}
              disabled={isSaving || (showPasswordField && !password)}
              sx={{ mt: 2, display: "block" }}
            >
              {isSaving
                ? "Updating..."
                : showPasswordField
                ? "Confirm Email Change"
                : "Update Email"}
            </Button>
          </Paper>

          {/* Danger Zone Section */}
          <Box sx={{ mt: 10, maxWidth: 400, width: "100%" }}>
            <Typography variant="h5" sx={{ mb: 1, color: "error.main" }}>
              Danger Zone
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Deleting your data is irreversible and will remove all your albums,
              tracks, and recordings from our systems. This will also remove recordings
              you have made in any shared albums. You'll be removed from any album that
              is shared with you, as well as any album you've been invited to.
              <strong>This action cannot be undone.</strong>
            </Typography>
            {deleteSuccess && (
              <Alert severity="success" sx={{ mt: 2, mb: 2, width: "100%" }}>
                All your data has been successfully deleted.
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2, mb: 2, width: "100%" }}>
                {error}
              </Alert>
            )}
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenDeleteDialog(true)}
              sx={{ mt: 2, display: "block" }}
            >
              Delete All My Data
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Subscription Management
            </Typography>
            {isLoadingSubscription ? (
              <CircularProgress sx={{ color: colors.gold }} />
            ) : subscriptionError ? (
              <Alert severity="error">{subscriptionError}</Alert>
            ) : subscription ? (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Status: {subscription.status}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Next billing date: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenCancelDialog(true)} // Open dialog
                >
                  Cancel Subscription
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  No active subscription found.
                </Typography>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubscribe}
                  sx={{ mt: 2 }}
                >
                  Subscribe to Digital Plan for $49
                </Button> */}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteUserData}
        title="Delete All Your Data?"
        content="This action will permanently delete all your albums, tracks, and recordings. This cannot be undone. Are you sure you want to proceed?"
        confirmText={isDeletingData ? "Deleting..." : "Delete All Data"}
      />

      {/* Cancel Subscription Confirmation Dialog */}
      <ConfirmDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={handleCancelSubscription}
        title="Cancel Subscription?"
        content="Are you sure you want to cancel your subscription? This action cannot be undone."
        confirmText="Cancel Subscription"
      />
    </ContainerBox>
  );
};

export default Profile;
